import React, { useState } from 'react';
import services from './apiservices';

const Card = () => {
  const [imageNo, setImageNo] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSearch = async () => {
    setLoading(true);
    setError('');

    try {
      const data = await services.getimagedata(imageNo);
      console.log(data);


      if (data && data.image_url) {
        window.open(data.image_url, '_blank'); // Open in a new tab
        console.log(data.image_url);
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }

    setImageNo('');
  };

  return (
    <div>
      <h1>Search Image by Image Number</h1>
      <input
        type="text"
        value={imageNo}
        onChange={(e) => setImageNo(e.target.value)}
        placeholder="Enter Image Number"
      />
      <button onClick={handleSearch}>Search</button>

      {loading && <p>Loading...</p>}

      {error && <p style={{ color: 'red' }}>{error}</p>}
    </div>
  );
};

export default Card;
