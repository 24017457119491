import axios from 'axios';

const services = {
    getimagedata: async (imageNo) => {
        console.log("hello from api ")
        try {
            console.log("image no form api", imageNo)
            const res = await axios.get(`api/cardb/getimage`, { params: { image_no: imageNo } });
            return res.data;
        } catch (error) {
            throw new Error(`Failed to get image data: ${error.message}`);
        }
    }
};

export default services;
